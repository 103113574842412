import * as Sentry from '@sentry/browser';
import { ChakraProvider } from '@chakra-ui/react';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { Capacitor } from '@capacitor/core';
import { Global, ClassNames, css } from '@emotion/react';
import { ToastContainer, cssTransition } from 'react-toastify';
import 'animate.css/animate.css';
import 'react-toastify/dist/ReactToastify.css';
import posthog from 'posthog-js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { FC, useEffect } from 'react';
import { render } from 'react-dom';
import { Redirect, BrowserRouter as Router, Route, useLocation, Switch } from 'react-router-dom';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { App } from '@capacitor/app';
import initializePosthog from './utils/initializePosthog';
import initializeOfflineToast from './utils/initializeOfflineToast';
import * as backHandling from './utils/backHandling';
import { buildPageSpacing, spacing, getGlobalStyles, fontStyles, colors, theme } from './styles';

import { runAllMigrations } from './migrations';
import * as stores from './stores';
import PortfolioPage from './components/PortfolioPage';
import SearchPage from './components/SearchPage';
import CollectionPage from './components/CollectionPage';
import SymbolPage from './components/SymbolPage';
import MorePage from './components/MorePage';
import SubscriptionTakeover from './components/SubscriptionTakeover';
import PlaygroundPage from './components/PlaygroundPage';
import { initializeTheme } from './utils/theme';
import { CapacitorUpdater } from '@capgo/capacitor-updater';

const queryClient = new QueryClient();

Purchases.configure({
  apiKey: Capacitor.getPlatform() === 'ios' ? 'appl_WzwoHcGpOkytDhvIXdkxELufsEY' : 'goog_KvaRdNEIDCrurgzrpZLEgfiKAJp'
});

export const updateNativeUI = async (isDark: boolean) => {
  if (Capacitor.isNativePlatform()) {
    try {
      await StatusBar.setStyle({
        style: isDark ? Style.Dark : Style.Light
      });

      if (Capacitor.getPlatform() === 'android') {
        await StatusBar.setBackgroundColor({
          color: isDark ? colors.modes.dark.statusBar : colors.modes.light.statusBar
        });
      }
    } catch (error) {
      console.error('Error updating native UI:', error);
    }
  }
};


Sentry.init({
  dsn: import.meta.env.PROD ? 'https://ec313205c4cd4f67957ea5afd7ed157a@o190156.ingest.sentry.io/1468344' : undefined,
});

backHandling.init();
backHandling.addBackListener(({ canGoBack }) => {
  if (canGoBack) {
    window.history.back();
  } else {
    App.exitApp();
  }
});

const RouterApp: FC = () => {
  const location = useLocation();
  const watchlist = stores.General.useState((s) => s.watchlist);

  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);

  const redirectPath = watchlist && Object.values(watchlist).some((value) => value) ? '/portfolio' : '/search';

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={redirectPath} />} />
      <Route exact path="/portfolio">
        <PortfolioPage />
      </Route>
      <Route exact path="/search">
        <SearchPage />
      </Route>
      <Route path="/more">
        <MorePage />
      </Route>
      <Route path="/symbol/:symbol">{(props: any) => <SymbolPage {...props} />}</Route>
      <Route path="/collection/:collectionId">
        <CollectionPage />
      </Route>
      <Route path="/playground">
        <PlaygroundPage />
      </Route>
    </Switch>
  );
};

const AppWrapper: FC = () => {
  const { isAppReady, isSearchOnboardingPopoverShown, isBookmarkOnboardingPopoverShown } = stores.General.useState();
  useEffect(() => {
    (async () => {
      try {
        await initializePosthog();
      } catch (e) {}

      initializeOfflineToast();

      await runAllMigrations();
      await Promise.all([stores.initializeGeneral(), stores.initializeTableOptions(), stores.initializePurchase()]);

        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        updateNativeUI(darkModeMediaQuery.matches);
  
        const handleDarkModeChange = (e: MediaQueryListEvent) => {
          updateNativeUI(e.matches);
        };
  
        darkModeMediaQuery.addEventListener('change', handleDarkModeChange);
  
        if (Capacitor.getPlatform() === 'ios') {
          StatusBar.setOverlaysWebView({ overlay: true });
          Keyboard.setAccessoryBarVisible({ isVisible: true });
          Keyboard.setResizeMode({ mode: KeyboardResize.None });
        }
  
        stores.General.update((s) => {
          s.isAppReady = true;
        });
        CapacitorUpdater.notifyAppReady();
  
        return () => {
          darkModeMediaQuery.removeEventListener('change', handleDarkModeChange);
        };
        
    })();
  }, []);

  if (!isAppReady) return <div />;

  return (
    <div
      onClick={() => {
        stores.General.update((s) => {
          s.isSearchOnboardingPopoverShown = false;
          s.isBookmarkOnboardingPopoverShown = false;
        });
      }}
    >
      <div
        css={
          (isSearchOnboardingPopoverShown || isBookmarkOnboardingPopoverShown) &&
          css`
            pointer-events: none;
          `
        }
      >
        <Router>
          <RouterApp />
          <SubscriptionTakeover />
        </Router>
      </div>
    </div>
  );
};

initializeTheme();

render(
  <div>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AppWrapper />
        <Global styles={getGlobalStyles()} />
        <ClassNames>
          {({ css }) => (
            <ToastContainer
              position="bottom-center"
              hideProgressBar
              draggable={false}
              newestOnTop
              closeButton={false}
              autoClose={3000}
              className={css`
                position: fixed;
                z-index: 300;
                padding: 0 ${buildPageSpacing(spacing.regular, 'RIGHT')} 0 ${buildPageSpacing(spacing.regular, 'LEFT')};
                margin-bottom: calc(8px + ${buildPageSpacing(spacing.giga, 'BOTTOM')});
              `}
              toastClassName={css`
                min-height: initial;
                color: ${colors.liquorice};
                background-color: ${colors.sugar};
                padding: ${spacing.deci};
                ${fontStyles.cat};
              `}
              bodyClassName={css`
                width: 100%;
                padding: 0;
              `}
              transition={cssTransition({
                enter: 'animate__animated animate__fadeInUp animate__faster',
                exit: 'animate__animated animate__fadeOutDown animate__faster',
              })}
            />
          )}
        </ClassNames>
      </QueryClientProvider>
    </ChakraProvider>
  </div>,
  document.getElementById('react-entry'),
);
