import { FC, useState, useEffect } from 'react';
import { Spinner } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { useQuery } from 'react-query';
import { getCollectionsList } from '../../../apis/backend';
import CollectionCard from '../../CollectionCard';
import { colors, spacing, mediaQuery } from '../../../styles';
import ErrorSection from '../../ErrorSection';
import { getCollectionImages } from '../../../utils/getImages';
import Button from '../../Button';
import * as stores from '../../../stores';

const styles = {
  list: css`
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${spacing.deci};
    padding: 0;

    @media (${mediaQuery.small}) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  `,
  item: css`
    position: relative;

    &::before {
      content: '';
      padding-bottom: 120%;
      display: block;

      @media (min-width: 370px) {
        padding-bottom: 112%;
      }
    }
  `,
  itemContent: css`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
  `,
  cardWrapper: css`
    width: 100%;
    height: 100%;
  `,
};

const CollectionList: FC = () => {
  const query = useQuery(['investments', 'collections'], async () => getCollectionsList());
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const [imageMap, setImageMap] = useState<Record<string, string>>({});

  useEffect(() => {
    const updateImages = () => {
      const newImageMap: Record<string, string> = {};
      query.data?.forEach(collection => {
        const imageSrc = getCollectionImages(collection.imageId);
        if (imageSrc && collection.imageId) {
          newImageMap[collection.imageId] = imageSrc;
        }
      });
      setImageMap(newImageMap);
    };

    updateImages();

    window.addEventListener('themeChanged', updateImages);
    return () => window.removeEventListener('themeChanged', updateImages);
  }, [query.data]);

  if (query.isLoading)
    return (
      <div
        css={css`
          text-align: center;
          margin-top: ${spacing.deka};
        `}
      >
        <Spinner color={colors.cloud} size="lg" />
      </div>
    );

  if (query.isError || !query.data)
    return (
      <div
        css={css`
          margin-top: ${spacing.deka};
        `}
      >
        <ErrorSection text="Problem fetching collections." onRetry={() => query.refetch()} />
      </div>
    );

  return (
    <ul css={styles.list}>
      {query.data.map((collection) => {
        const imageSrc = imageMap[collection.imageId ?? ''];
        if (!imageSrc) return null;
        const isRestricted =
          collection.restrictedToSubscribers &&
          !purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;

        const cardComponent = <CollectionCard name={collection.name} imageSrc={imageSrc} isLocked={isRestricted} />;

        return (
          <li key={collection.id} css={styles.item}>
            <div css={styles.itemContent}>
              <div css={styles.cardWrapper}>
                {isRestricted ? (
                  <Button
                    onClick={() =>
                      stores.General.update((s) => {
                        s.isSubscriptionTakeoverShown = true;
                      })
                    }
                    css={css`
                      height: 100%;
                      width: 100%;
                    `}
                  >
                    {cardComponent}
                  </Button>
                ) : (
                  <Link to={`/collection/${collection.id}`}>{cardComponent}</Link>
                )}
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default CollectionList;
