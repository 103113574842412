import { FC, useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { Spinner } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import posthog from 'posthog-js';
// Remove the old import
// import { Purchases, PurchasesPackage } from '@awesome-cordova-plugins/purchases';
// Add the new import
import { Purchases, PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { css } from '@emotion/react';
import * as stores from '../../stores';
import { colors, darkmodeStyles, fontStyles, spacing } from '../../styles';
import Takeover, { Props as TakeoverProps } from '../Takeover';
import { toast } from 'react-toastify';
import Button from '../Button';
import RadioButton, { Props as RadioButtonProps } from '../RadioButton';
import { getSubscriptionTakeoverImages } from '../../utils/getImages';
import selected from './selected.svg';


const PurchaseRadioCard: FC<
  RadioButtonProps & {
    title: string;
    savingsText?: string;
    price: string;
    unit: string;
    priceBreakdown?: string;
    introText?: string;
  }
> = ({ checked, title, savingsText, price, unit, priceBreakdown, introText, ...props }) => {
  return (
    <RadioButton
      {...props}
      checked={checked}
      css={css`
        width: 138px;
        height: 146px;
        padding: 0;
        text-align: center;
        color: ${colors.tungsten};
        -webkit-tap-highlight-color: transparent;
        display: flex;
        flex-direction: column;
        border: none;
        border-radius: 8px;
        box-shadow: 0 0 0 ${checked ? '2px' : '1px'} ${checked ? colors.petrolBlue : colors.cloud}, 0 2px 3px 0 #e5e5e5;
        overflow: hidden;
        html[data-theme='dark'] & {
          color: ${colors.tungsten};
        }
      `}
    >
      <div
        css={css`
          background-color: ${checked ? colors.petrolBlue : colors.cloud};
          color: ${checked ? colors.sugar : colors.liquorice};
          height: 50px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: ${spacing.deci};
          ${fontStyles.roboCat}
        `}
      >
        <div
          css={css`
            position: relative;
          `}
        >
          {checked && (
            <img
              src={selected}
              css={css`
                position: absolute;
                left: 0;
                top: -1px;
              `}
            />
          )}
          {title}
        </div>
      </div>
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: ${spacing.deci};
        `}
      >
        <div
        css={css`
          margin-bottom: ${spacing.centi};
        `}>
          <span
            css={css`
              ${fontStyles.lion}
              color: ${colors.liquorice};
                ${darkmodeStyles.regular}
            `}
          >
            {price}
          </span>{' '}
          <span css={css`
            color: ${colors.silver};
            ${fontStyles.flea}
          `}>
            / {unit}
          </span>
        </div>
        <div
          css={css`
            color: ${colors.silver};
            ${fontStyles.flea}
          `}
        >

          {priceBreakdown}
        </div>
      </div>
    </RadioButton>
  );
};

const onClose = () =>
  stores.General.update((s) => {
    s.isSubscriptionTakeoverShown = false;
  });

const SubscriptionTakeover: FC<Omit<TakeoverProps, 'isShown' | 'onClose'>> = (props) => {
  const isShown = stores.General.useState((s) => s.isSubscriptionTakeoverShown);
  const [selectedPackage, setSelectedPackage] = useState<PurchasesPackage>();
  const offerings = stores.Purchase.useState((s) => s.offerings);
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const purchaseMutation = useMutation((selectedPackage: PurchasesPackage) =>
    // Update the purchase method call
    Purchases.purchasePackage({ aPackage: selectedPackage })
  );
  const restoreMutation = useMutation(() => Purchases.restorePurchases());
  const [images, setImages] = useState(getSubscriptionTakeoverImages());

  useEffect(() => {
    const handleThemeChange = () => {
      setImages(getSubscriptionTakeoverImages());
    };

    window.addEventListener('themeChanged', handleThemeChange);
    return () => window.removeEventListener('themeChanged', handleThemeChange);
  }, []);

  useEffect(() => {
    if (offerings?.current?.annual) {
      setSelectedPackage(offerings.current.annual);
    }
  }, [offerings]);

  const isSubscribed = purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;

  const annualPackage = offerings?.current?.annual;
  const monthlyPackage = offerings?.current?.monthly;

  return (
    <Takeover {...props} title="Join Bloom today" isShown={isShown} onClose={onClose} name="membership">

      {isSubscribed ? (
        <div
          css={css`
            text-align: center;
            display: flex;
            flex-direction: column;
            min-height: 100%;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              margin-top: 6vh;
            `}
          >
            <img
              src={images.confetti}
              css={css`
                height: 150px;
              `}
            />
            <h2
              css={css`
                margin: ${spacing.hecto} 0;
                ${fontStyles.shark}
              `}
            >
              Subscription activated!
            </h2>
            <p
              css={css`
                margin: 0;
                ${fontStyles.dog}
              `}
            >
              Congratuations, you now have access to all Bloom features.
            </p>
          </div>
          <div>
            <Button
              block
              variant="primary"
              onClick={onClose}
              css={css`
                ${fontStyles.horse}
              `}
            >
              Done
            </Button>
          </div>
        </div>
      ) : (
        <div
          css={css`
            min-height: 100%;
          `}
        >
          <div>
          <h2
              css={css`
               margin: 0 0 ${spacing.regular} 0;
                ${fontStyles.lion}
              `}
            >
              Subscribe to get access to:
            </h2>
            <ul
              css={css`
                list-style: none;
                padding: 0;
                margin: 0;
                ${fontStyles.mouse}
                ${darkmodeStyles.regular}
              `}
            >
              {[
                [images.wiim, 'Why is it moving for all dates', 'Understand how historical news affects stock prices.'],
                [images.collections, 'All curated collections', 'Discover undervalued stocks with our pre-built screeners.'],
                [images.reports, 'AI-powered bottom line', 'Unlock knowledge hidden in earnings calls and investor reports.'],
                [images.newbie, 'Support Bloom’s development', 'Bloom is made by a single indie developer!'],
              ].map(([image, title, text]) => (
                <li
                  key={text}
                  css={css`
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: ${spacing.regular};
                  `}
                >
                  <div 
                  css={css`
                    flex-shrink: 0; 
                  `}>
                    <img
                      src={image}
                      css={css`
                        width: 36px;
                      `}
                    />
                  </div>
                  <div
                  css={css`
                    margin-left: ${spacing.deci};
                  `}
                >
                  <div
                    css={css`
                      ${fontStyles.cat}
                      margin-bottom: 2px;
                      font-weight: 500;
                    `}
                  >
                    {title}
                  </div>
                  <div
                    css={css`
                      ${fontStyles.cat}
                      font-size: 0.75rem;
                      line-height: 1.19rem;
                  `}>{text}</div> {' '}{'         '}
                </div>
                </li>
              ))}
            </ul>
            <div
              role="radiogroup"
              css={css`
                margin: ${spacing.deka} 0;
                display: flex;
                justify-content: space-evenly;
              `}
            >
              {[monthlyPackage, annualPackage].map((rcPackage) => {
                if (!rcPackage) return null;
                const currencySymbol = rcPackage?.product?.priceString?.[0];

                const packageText: Record<
                  string,
                  { title: string; unit: string; savingsText?: string; priceBreakdown?: string; freeTrialText?: string }
                > = {
                  $rc_monthly: {
                    title: 'Monthly',
                    unit: 'month',
                  },
                  $rc_annual: {
                    title: 'Yearly',
                    unit: 'year',
                    savingsText: monthlyPackage
                      ? `Save ${((1 - rcPackage.product.price / 12 / monthlyPackage.product.price) * 100).toFixed(0)}%`
                      : undefined,
                    priceBreakdown: `${currencySymbol}${(rcPackage.product.price / 12).toFixed(2)} /month`,
                  },
                };
                return (
                  <div
                    key={rcPackage.identifier}
                    css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
                  >
                    <PurchaseRadioCard
                      checked={selectedPackage === rcPackage}
                      price={rcPackage.product.priceString}
                      {...packageText[rcPackage.identifier]}
                      onChange={() => setSelectedPackage(rcPackage)}
                      css={css`margin: 0;`}
                    />
                    {rcPackage.product.introPrice?.price === 0 && rcPackage.product.introPrice.periodUnit && (
                      <div css={css`
            margin-top: ${spacing.milli};
            color: ${colors.leaf};
            ${fontStyles.flea}
          `}>
                        +{rcPackage.product.introPrice.periodNumberOfUnits}{' '}
                        {rcPackage.product.introPrice.periodUnit.toLowerCase()} free trial
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            {!purchaseMutation.isLoading &&
              purchaseMutation.isError &&
              !(purchaseMutation.error as any)?.userCancelled && (
                <p
                  css={css`
                    margin: ${spacing.centi} 0;
                    text-align: center;
                    color: ${colors.berry};
                    ${fontStyles.flea}
                  `}
                >
                  There was an unexpected error. Please try again.
                </p>
              )}
            <Button
              variant="primary"
              block
              disabled={!selectedPackage || purchaseMutation.isLoading}
              css={css`
                ${fontStyles.horse}
              `}
              onClick={() => {
                (async () => {
                  if (selectedPackage) {
                    try {
                      const { customerInfo } = await purchaseMutation.mutateAsync(selectedPackage);
                      stores.Purchase.update((s) => {
                        s.purchaserInfo = customerInfo;
                      });
                      posthog.capture('Order Completed', {
                        'Package identifier': selectedPackage.identifier,
                      });
                    } catch (e) {
                      if (!e.userCancelled) {
                        Sentry.captureException(e);
                      }
                      console.log(e);
                    }
                  }
                })();
              }}
            >
              <span
                css={css`
                  justify-content: center;
                  align-items: center;
                `}
              >

                {purchaseMutation.isLoading ? <Spinner color={colors.sugar} /> : <>Subscribe</>}
              </span>
            </Button>
            <Button
              block
              disabled={restoreMutation.isLoading}
              css={css`
                padding: ${spacing.deci};
                ${fontStyles.mouse}
              `}
              onClick={() => {
                (async () => {
                  try {
                    const { customerInfo } = await restoreMutation.mutateAsync();
                    stores.Purchase.update((s) => {
                      s.purchaserInfo = customerInfo;
                    });
                  } catch (error) {
                    console.error('Failed to restore purchases:', error);
                    toast('We could not find your subscription.');
                    // Optionally, you can show an error message to the user here
                  }
                })();
              }}
            >
              {restoreMutation.isLoading ? <Spinner size="sm" color={colors.cloud} /> : <>Restore Purchase</>}
            </Button>
            <div
              css={css`
                color: ${colors.silver};
                text-align: center;
                ${fontStyles.flea}
              `}
            >
              <span css={css`
                padding: ${spacing.deci};
              `}>All subscriptions can be cancelled at anytime. You won’t be charged if you cancel before your free trial ends.</span>
            </div>

            <div
              css={css`
                color: ${colors.silver};
                text-align: center;
                margin-top: ${spacing.deci};
                ${fontStyles.flea}
              `}
            >
              <p
                css={css`
                  margin: ${spacing.centi} 0;
                `}
              >
                <Button
                  onClick={() =>
                    window.open(
                      'https://www.notion.so/Bloom-Terms-and-Conditions-b41a2b80c0b14429801adf810856b1d3',
                      '_blank',
                    )
                  }
                >
                  Terms and Conditions
                </Button>{' '}
                and{' '}
                <Button
                  onClick={() =>
                    window.open('https://www.notion.so/Bloom-Privacy-Policy-0c94935f49344ce4957fe459f4cf128d', '_blank')
                  }
                >
                  Privacy Policy
                </Button>
              </p>
            </div>
          </div>
        </div>
      )}
    </Takeover>
  );
};

export default SubscriptionTakeover;
