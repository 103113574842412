import { isNil } from 'lodash-es';

export enum FormatterStyle {
  moneyBig,
  moneyBigDetailed,
  moneySmall,
  number,
  percent,
  percentDetailed,
  percentDetailed2,
  percentChange,
}

export default (type: FormatterStyle, value?: number) => {
  if (isNil(value)) {
    return '';
  }

  const formatters = {
    [FormatterStyle.moneyBig]: () => {
      const isBillions = Math.abs(value) >= 10 ** 9;

      const displayNumber = (isBillions ? value / 10 ** 9 : value / 10 ** 6).toFixed(1).toLocaleString();
      const unit = isBillions ? 'B' : 'M';
      return `$${displayNumber}\u200A${unit}`;
    },

    [FormatterStyle.moneyBigDetailed]: () => {
      const isBillions = value >= 10 ** 9;

      const displayNumber = (isBillions ? value / 10 ** 9 : value / 10 ** 6).toFixed(1).toLocaleString();
      const unit = isBillions ? 'Billion' : 'Million';
      return `$${displayNumber} ${unit}`;
    },

    [FormatterStyle.moneySmall]: () =>
      '$' + value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

    [FormatterStyle.number]: () => value.toFixed(2),

    [FormatterStyle.percent]: () => `${Math.round(value * 100)}%`,

    [FormatterStyle.percentDetailed]: () => `${(value * 100).toFixed(1)}%`,

    [FormatterStyle.percentDetailed2]: () => `${(value * 100).toFixed(2)}%`,

    [FormatterStyle.percentChange]: () => `${value > 0 ? '+' : '-'} ${Math.abs(value * 100).toFixed(1)}%`,
  } as any;

  return formatters[type]();
};
