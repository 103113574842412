import { FC } from 'react';
import { css } from '@emotion/react';
import { Rating } from '../../apis/backend';
import { fontStyles, spacing, ratingTextColor, darkmodeStyles } from '../../styles';
import Box from '../Box';
import Indicator, { Size } from '../RatingIndicator';

interface Props {
  heading: string;
  description: string;
  formulas: { rating: Exclude<Rating, Rating.Missing>; formulaText: string }[];
}

const ratingTextMapping = {
  [Rating.Exceptional]: 'Exceptional',
  [Rating.Favorable]: 'Favorable',
  [Rating.Average]: 'Average',
  [Rating.Unfavorable]: 'Unfavorable',
  [Rating.Dangerous]: 'Dangerous',
};

const RatingBox: FC<Props> = ({ heading, description, formulas }) => {
  return (
    <Box>
      <h3
        css={css`
          margin: 0;
          ${fontStyles.lion}
        `}
      >
        {heading}
      </h3>
      <p
        css={css`
          margin: ${spacing.deci} 0 ${spacing.deci} 0;
          ${fontStyles.mouse}
        `}
      >
        {description}
      </p>
      <ul
        css={css`
          list-style: none;
          ${fontStyles.mouse}
          padding-left: ${spacing.deci};
          margin: 0;
        `}
      >
        {formulas.map(({rating, formulaText }, index) => (
          <li
            key={index}
            css={css`
              margin-bottom: ${spacing.deci};
              display: flex;
              align-items: center;
            `}
          >
            <Indicator heading={heading} rating={rating} size={Size.medium} />
            <span>
              <span
                css={css`
                  color: ${ratingTextColor[rating]};
                  margin-left: ${spacing.deci};
                `}
              >
                {ratingTextMapping[rating]}
              </span>{' '}
              <span
                css={css`
                  ${darkmodeStyles.regular}
                `}
              >
                = {formulaText}
              </span>
            </span>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default RatingBox;
