import { css } from '@emotion/react';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { colors, fontStyles, spacing } from '../../../styles';
import SectionHeading from '../SectionHeading';
import Button from '../../Button';
import Hr from '../../Hr';
import externalLink from '../../../assets/externalLink.svg';
import { getInsights } from '../../../apis/backend';
import { Link, useRouteMatch } from 'react-router-dom';

interface KeyPoint {
  page: number;
  details: string;
}

interface PotentialRisk {
  page: number;
  details: string;
}

interface Insight {
  id: number;
  keyPoints: KeyPoint[];
  potentialRisks: PotentialRisk[];
  fileUrl: string;
  created: string;
  updated: string;
  summary: string;
  title: string;
  articleDate: string;
  investment: string;
}

interface InsightResponse {
  results: Insight[];
}

interface Props {
  symbol: string;
}

const Insights: FC<Props> = ({ symbol }) => {
  const match = useRouteMatch();
  const { data, isLoading } = useQuery<InsightResponse>(
    ['investments', symbol, 'insights'],
    async () => {
      const response = await getInsights(symbol);
      return { results: Array.isArray(response) ? response : [] };
    }
  );

  if (isLoading || !data?.results?.[0]) return null;

  const insight = data.results[0];
  const date = new Date(insight.articleDate).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });

  return (
    <div>
      <Hr />
      <SectionHeading text="Insights" />
      
      <div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: ${spacing.deci};
          `}
        >
          <a 
            href={insight.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            css={css`
              color: ${colors.petrolBlue};
              text-decoration: none;
              ${fontStyles.roboCat}
            `}
          >
            {insight.title}
            <img
              src={externalLink}
              css={css`
                vertical-align: text-bottom;
                width: 1.25rem;
                height: 1.25rem;
              `}
            />
          </a>
          <span
            css={css`
              color: ${colors.silver};
              ${fontStyles.roboCat}
            `}
          >
            {date}
          </span>
        </div>

        <p
          css={css`
            margin: ${spacing.deci} 0;
            color: ${colors.tungsten};
            ${fontStyles.cat}
          `}
        >
          {insight.summary}
        </p>

        <div
          css={css`
            display: flex;
            gap: ${spacing.deci};
            margin: ${spacing.deci} 0;
          `}
        >
          <span
            css={css`
              color: ${colors.petrolBlue};
              border: 1px solid ${colors.petrolBlue};
              border-radius: 16px;
              padding: ${spacing.milli} ${spacing.deci};
              ${fontStyles.mouse}
            `}
          >
            {insight.keyPoints.length} key points
          </span>
          <span
            css={css`
              color: ${colors.petrolBlue};
              border: 1px solid ${colors.petrolBlue};
              border-radius: 16px;
              padding: ${spacing.milli} ${spacing.deci};
              ${fontStyles.mouse}
            `}
          >
            {insight.potentialRisks.length} potential risks
          </span>
        </div>

        <Link to={`${match.url}/insights`}>
          <Button
            variant="secondary"
            block
            css={css`
              margin-top: ${spacing.deka};
            `}
          >
            See all insights ({data.results.length})
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Insights;