import { FC, ComponentPropsWithoutRef } from 'react';
import { css } from '@emotion/react';
import { isNumber } from 'lodash-es';
import { transparentize } from 'polished';
import { calculateChangeColor } from '../../styles';
import formatDisplay, { FormatterStyle } from '../../utils/formatDisplay';

interface Props extends ComponentPropsWithoutRef<'span'> {
  value?: number;
}

const ColoredPercentChange: FC<Props> = ({ value, ...props }) => {
  return (
    <span
      css={css`
        color: ${isNumber(value) ? calculateChangeColor(value) : 'inherit'};
        background-color: ${isNumber(value) ? transparentize(0.9, calculateChangeColor(value)) : 'inherit'};
        padding: 6px 10px;
        border-radius: 4px;
      `}
      {...props}
    >
      {formatDisplay(FormatterStyle.percentChange, value)}
    </span>
  );
};

export default ColoredPercentChange;
