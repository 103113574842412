import { FC, ComponentPropsWithoutRef } from 'react';
import { css } from '@emotion/react';
import { Spinner } from '@chakra-ui/react';
import { colors, fontStyles, spacing } from '../../styles';
import arrowForward from './arrowForward.svg';

interface Props extends ComponentPropsWithoutRef<'button'> {
  isLoading?: boolean;
}

const RowButton: FC<Props> = ({ children, isLoading, ...props }) => {

  const styles = {
    darkmodeStyle: css`
      html[data-theme='dark'] & {
        background-color: ${colors.liquorice};
        color: ${colors.sugar};
      }      
  `,
  };
  
  return (
    <button
      css={css`
        display: block;
        width: 100%;
        background-color: transparent;
        border: none;
        padding: ${spacing.deci} 0;
        text-align: left;
        ${fontStyles.cat}
        ${styles.darkmodeStyle} 
        &:hover {
          cursor: pointer;
        }
      `}
      {...props}
    >
      {children}
      {isLoading ? (
        <span
          css={css`
            vertical-align: middle;
            float: right;
          `}
        >
          <Spinner size="sm" color={colors.cloud} />
        </span>
      ) : (
        <img
          src={arrowForward}
          css={css`
            vertical-align: middle;
            float: right;
          `}
        />
      )}
    </button>
  );
};

export default RowButton;
