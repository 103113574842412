import { FC, ReactNode } from 'react';
import { css } from '@emotion/react';
import { colors, fontStyles, spacing } from '../../../styles';

interface Props {
  text: ReactNode;
  isLatest?: boolean;
}

const Dot: FC<{ isFilled?: boolean }> = ({ isFilled }) => (
  <span
    css={css`
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      background-color: ${isFilled ? colors.petrolBlue : 'transparent'};
      border: solid 2px ${colors.petrolBlue};
      margin-left: ${spacing.milli};
      position: relative;
      top: 1px;
    `}
  />
);

const Wiim: FC<Props> = ({ text, isLatest }) => {
  return (
    <div>
      <h2
        css={css`
          margin: 0 0 ${spacing.centi} 0;
          color: ${colors.liquorice};
          ${fontStyles.flea}
          html[data-theme='dark'] & {
            color: ${colors.sugar};
          } 
        `}
      >
        Why is it moving?
        <span
          css={css`
            margin-left: ${spacing.centi};
            color: ${colors.silver};
            ${fontStyles.flea};
            html[data-theme='dark'] & {
              color: ${colors.sugar};
            } 
          `}
        >
          {isLatest ? (
            <>
              Latest
              <Dot />
            </>
          ) : (
            <>
              Selected
              <Dot isFilled />
            </>
          )}
        </span>
      </h2>
      <p
        css={css`
          margin: 0;
          color: ${colors.tungsten};
          ${fontStyles.flea}
          html[data-theme='dark'] & {
            color: ${colors.sugar};
          } 
        `}
      >
        {text}
      </p>
    </div>
  );
};

export default Wiim;
