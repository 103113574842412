import { css } from '@emotion/react';
import { without } from 'lodash';
import { FC, useState } from 'react';
import Takeover from '../../Takeover';
import { colors, spacing, fontStyles } from '../../../styles';
import Button from '../../Button';
import { TimeRange } from '../../../types';
import Select from '../../Select';
import TimeRangeRadios from '../../TimeRangeRadios';
import { ColumnSelection, ColumnSelectionType } from '../columns';
import BottomLineExplanationTakeover from '../../BottomLineExplanationTakeover';
import leftLabel from './left.svg';
import middleLabel from './middle.svg';
import rightLabel from './right.svg';

interface Props {
  isShown: boolean;
  columnSelections: ColumnSelection[];
  onClose: () => void;
  onChange: (index: number, value: ColumnSelection) => void;
}

const columnIdToOptionText = {
  [ColumnSelectionType.Name]: 'Name',
  [ColumnSelectionType.Symbol]: 'Symbol',
  [ColumnSelectionType.ChangePercent]: 'Change percent',
  [ColumnSelectionType.BottomLine]: 'Bottom line',
  [ColumnSelectionType.LatestPrice]: 'Latest price',
  [ColumnSelectionType.RevenueGrowth1y]: 'Revenue growth (1y)',
  [ColumnSelectionType.PercentMarketCapTraded1d]: 'Traded percent (1d)',
  [ColumnSelectionType.PriceToEarnings]: 'Price to earnings',
  [ColumnSelectionType.PriceToSales]: 'Price to sales',
  [ColumnSelectionType.Week52Range]: '52 week high-low',
  [ColumnSelectionType.MarketCap]: 'Market cap',
  [ColumnSelectionType.CashflowYield]: 'Cashflow yield',
  [ColumnSelectionType.EvToGrossProfit]: 'EV to gross profit',
  [ColumnSelectionType.InsidersPercentTraded3m]: 'Insider trades percent (3m)',
};

const Field: FC<Omit<Props, 'isShown' | 'onClose'> & { index: number }> = ({ index, columnSelections, onChange }) => {
  const [isBottomLineExplanationShown, setIsBottomLineExplanationShown] = useState(false);
  const label = [
    { src: leftLabel, alt: 'Left' },
    { src: middleLabel, alt: 'Middle' },
    { src: rightLabel, alt: 'Right' },
  ];
  const options =
    index === 0
      ? [ColumnSelectionType.Name, ColumnSelectionType.Symbol]
      : without(Object.values(ColumnSelectionType), ColumnSelectionType.Name, ColumnSelectionType.Symbol);

  return (
    <div
      css={css`
        display: flex;
        margin-bottom: ${spacing.deka};
      `}
      role="group"
      aria-labelledby={`columnActionSheetLabel-${index}`}
    >
      <BottomLineExplanationTakeover
        isShown={isBottomLineExplanationShown}
        onClose={() => setIsBottomLineExplanationShown(false)}
      />
      <span
        css={css`
          padding-top: 6px;
        `}
      >
        <img id={`columnActionSheetLabel-${index}`} {...label[index]} />
      </span>
      <div
        css={css`
          flex: 1;
          margin-left: ${spacing.deci};
        `}
      >
        <Select
          css={css`
            margin-bottom: ${spacing.deci};
          `}
          value={columnSelections[index].type}
          onChange={(event) => {
            const is1WeekAlreadySelected = columnSelections.some(
              (selection) =>
                selection.timeRange === TimeRange.weeks1 && selection.type === ColumnSelectionType.ChangePercent,
            );
            const timeRange =
              event.target.value !== ColumnSelectionType.ChangePercent
                ? undefined
                : is1WeekAlreadySelected
                ? TimeRange.months1
                : TimeRange.weeks1;
            onChange(index, { type: event.target.value as ColumnSelectionType, timeRange });
          }}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {columnIdToOptionText[option]}
            </option>
          ))}
        </Select>
        {columnSelections[index].type === ColumnSelectionType.BottomLine && (
          <Button
            onClick={() => setIsBottomLineExplanationShown(true)}
            css={css`
              ${fontStyles.flea}
            `}
          >
            What’s the Bottom Line?
          </Button>
        )}
        {columnSelections[index].type === ColumnSelectionType.ChangePercent && (
          <TimeRangeRadios
            timeRanges={Object.values(TimeRange)}
            selectedTimeRange={columnSelections[index].timeRange}
            onRadioChange={(timeRange) => {
              onChange(index, { type: columnSelections[index].type, timeRange });
            }}
          />
        )}
      </div>
    </div>
  );
};

const ColumnTakeover: FC<Props> = ({ isShown, columnSelections, onClose, onChange }) => {
  if (columnSelections?.length === 0) return <div />;

  return (
    <Takeover title="Change columns" onClose={onClose} isShown={isShown} name="ChangeColumns">
      <form
        css={css`
          color: ${colors.liquorice};
        `}
        onSubmit={(event) => {
          event.preventDefault();
          onClose();
        }}
      >
        <Field index={0} columnSelections={columnSelections} onChange={onChange} />
        <Field index={1} columnSelections={columnSelections} onChange={onChange} />
        <Field index={2} columnSelections={columnSelections} onChange={onChange} />

        <Button
          variant="secondary"
          block
          type="submit"
          css={css`
            margin-top: ${spacing.kilo};
          `}
        >
          Done
        </Button>
      </form>
    </Takeover>
  );
};

export default ColumnTakeover;
