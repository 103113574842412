import { FC } from 'react';
import { css } from '@emotion/react';
import { darkmodeStyles, fontStyles, spacing } from '../../styles';
import Takeover, { Props } from '../Takeover';
import { Rating } from '../../apis/backend';
import Button from '../Button';
import RatingBox from './RatingBox';

const BottomLineExplanationTakeover: FC<Props> = (props) => (
  <Takeover {...props} title="Introducing the Bottom Line" name="BottomLineExplanation">
    <p
      css={css`
        margin-bottom: ${spacing.regular};
        ${fontStyles.cat}
      `}
    >
      The Bottom Line is a rating system that helps you see a company&apos;s health at a glance. The ratings are based
      on metrics that indicate the fundamental value of an investment. See below for a more detailed breakdown.
    </p>
    <div
      css={css`
        margin-bottom: ${spacing.regular};
      `}
    >
      <RatingBox
        heading="Profitability Rating"
        description="Based on a company's net margin, which is their ability to make money after paying expenses."
        formulas={[
          { rating: Rating.Exceptional, formulaText: '+20% or higher' },
          { rating: Rating.Favorable, formulaText: '+5% to +20%' },
          { rating: Rating.Average, formulaText: '-5% to +5%' },
          { rating: Rating.Unfavorable, formulaText: '-5% to -20%' },
          { rating: Rating.Dangerous, formulaText: '-20% or lower' },
        ]}
      />
    </div>
    <div
      css={css`
        margin-bottom: ${spacing.regular};
      `}
    >
      <RatingBox
        heading="Growth Rating"
        description="Based on a company's revenue growth, which is their ability to grow sales at a high rate."
        formulas={[
          { rating: Rating.Exceptional, formulaText: '+40% or higher' },
          { rating: Rating.Favorable, formulaText: '+10% to +40%' },
          { rating: Rating.Average, formulaText: '-5% to +10%' },
          { rating: Rating.Unfavorable, formulaText: '-20% to -5%' },
          { rating: Rating.Dangerous, formulaText: '-20% or lower' },
        ]}
      />
    </div>
    <div
      css={css`
        margin-bottom: ${spacing.regular};
      `}
    >
      <RatingBox
        heading="Price Rating"
        description="Based on a company's valuation multiple compared to the industry average. We use price / earnings by default. For companies with low profitability, we use enterprise value / sales."
        formulas={[
          { rating: Rating.Exceptional, formulaText: '0.3x or lower' },
          { rating: Rating.Favorable, formulaText: '0.3x to 0.7x' },
          { rating: Rating.Average, formulaText: '0.7x to 1.5x' },
          { rating: Rating.Unfavorable, formulaText: '1.5x to 3x' },
          { rating: Rating.Dangerous, formulaText: '3x or higher' },
        ]}
      />
    </div>
    <div
      css={css`
        margin-bottom: ${spacing.regular};
      `}
    >
      <RatingBox
        heading="Insider Activity Rating"
        description="Based on the percent of company shares bought or sold by insiders in the last 90 days. We also flag it as unfavorable if float is < 80% of the total shares outstanding, or if there is high share growth > 10%."
        formulas={[
          { rating: Rating.Favorable, formulaText: '0.5%+ bought' },
          { rating: Rating.Average, formulaText: '-0.5% to 0.5%' },
          { rating: Rating.Unfavorable, formulaText: '0.5%+ sold' },
        ]}
      />

      <h2
        css={css`
          margin: ${spacing.hecto} 0 ${spacing.deci} 0;
          ${fontStyles.horse}
        `}
      >
        Should I buy highly rated stocks?
      </h2>
      <div
        css={css`
          ${fontStyles.cat}
        `}
      >
        <p
          css={css`
            margin: 0;
          `}
        >
          The Bottom Line is like taking the temperature of a company. It gives you high level indications of general
          health. But before you make a buy or sell decision, you should have a sense of:
        </p>
        <ul
          css={css`
            padding-left: ${spacing.deka};
            ${darkmodeStyles.regular}
          `}
        >
          <li>potential catalysts for the stock price</li>
          <li>the company strategy</li>
          <li>comparison to peers</li>
          <li>news and events</li>
          <li>your personal risk appetite</li>
        </ul>
      </div>
    </div>
    <Button
      variant="secondary"
      block
      onClick={() => {
        props.onClose();
      }}
      css={css`
        margin-top: ${spacing.regular};
        ${fontStyles.cat}
      `}
    >
      Close
    </Button>
  </Takeover>
);

export default BottomLineExplanationTakeover;
