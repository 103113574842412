import { v4 as uuidv4 } from 'uuid';
import posthog from 'posthog-js';
import * as storage from '../storage';
import { generalStoreId, SavedGeneral } from '../stores';

const getAndSetPosthogUserId = async (): Promise<string> => {
  const key = 'posthogUserId';
  const storedId = await storage.get<string>(key);
  if (storedId) return storedId;
  const generatedId = uuidv4();
  await storage.set(key, generatedId);
  return generatedId;
};

export default async () => {
  const [userId, saved] = await Promise.all([getAndSetPosthogUserId(), storage.get<SavedGeneral>(generalStoreId)]);

  posthog.init('phc_eiZdbRNnYSVXPQEdkxS8Pxy7Rk98l7Xcn903LoYdOcQ', {
    api_host: 'https://app.posthog.com',
    loaded: (posthog) => {
      posthog.register({
        'App Open Count': saved?.appOpenDates?.length ?? 0 + 1,
      });
      posthog.identify(userId);
    },
    opt_out_capturing_by_default: import.meta.env.DEV,
  });
};
