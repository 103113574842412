import { FC, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { keyBy, pickBy, mapValues } from 'lodash';
import { format, sub } from 'date-fns';
import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import * as stores from '../../stores';
import { TimeRange } from '../../types';
import { useInvestmentsCacheState } from '../../utils/persistedState';
import { InvestmentTable, ColumnSelectionType } from '../InvestmentTable';
import { getBasicInvestments, getWiims } from '../../apis/backend';
import Button from '../Button';
import Page from '../Page';
import { colors, spacing, fontStyles } from '../../styles';
import Empty from './Empty';

const PortfolioPage: FC = () => {
  const baseStyle = css`
    position: relative;
    color: ${colors.liquorice};
  `;

  const topStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 ${spacing.regular} 0;
  `;

  const headingStyle = css`
    color: ${colors.liquorice};
    margin: 0;
    ${fontStyles.shark};
    html[data-theme='dark'] & {
      color: ${colors.sugar};
    }
  `;

  const tableId = 'portfolio';

  const watchlist = stores.General.useState((s) => s.watchlist);
  const [investmentsCache, setInvestmentsCache] = useInvestmentsCacheState();
  const columnSelections = stores.TableOptions.useState((s) => s[tableId]?.columnSelections) || [
    { type: ColumnSelectionType.Name },
    { type: ColumnSelectionType.Week52Range },
    { type: ColumnSelectionType.ChangePercent, timeRange: TimeRange.weeks1 },
  ];
  const sortingRule = stores.TableOptions.useState((s) => s[tableId]?.sortingRule) || { id: 'name' };
  const filters = stores.TableOptions.useState((s) => s[tableId]?.filters) || { stocks: false, funds: false };

  const dateFrom = format(sub(new Date(), { weeks: 1 }), 'yyyy-MM-dd');
  const pickedWatchlist = pickBy(watchlist, (value) => value!);
  const watchlistList = Object.keys(pickedWatchlist);

  const getWatchlist = async () => {
    const data = await getBasicInvestments(watchlistList);
    setInvestmentsCache(
      keyBy(
        data.map(({ symbol, name, category }) => ({
          symbol,
          name,
          category,
        })),
        'symbol',
      ),
    );
    return data;
  };

  const errorToastId = 'getWatchlistError';
  const investmentsResult = useQuery(['investments', { symbols: pickedWatchlist }], getWatchlist, {
    enabled: watchlistList.length > 0,
    onError: () => {
      toast(
        () => (
          <Button
            css={css`
block
              display: flex;
              justify-content: space-between;
              outline: none;
              color: ${colors.liquorice};
            `}
            onClick={() => {
              toast.dismiss(errorToastId);
              investmentsResult.refetch();
            }}
          >
            <span>Problem loading watchlist data.</span>
            <span
              css={css`
                color: ${colors.petrolBlue};
                margin-left: 4px;
              `}
            >
              Retry
            </span>
          </Button>
        ),
        { toastId: errorToastId, autoClose: false },
      );
    },
    onSuccess: () => {
      toast.dismiss(errorToastId);
    },
  });
  const wiimsResult = useQuery(
    ['investments', 'wiims', { symbols: pickedWatchlist, dateFrom }],
    () => getWiims(watchlistList, { dateFrom }),
    { enabled: watchlistList.length > 0 },
  );

  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const isSubscribed = purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;
  const appOpenDates = stores.General.useState((s) => s.appOpenDates);

  useEffect(() => {
    stores.General.update((s) => {
      s.sessionWatchlistViewCount++;
    });
    return () => {
      toast.dismiss(errorToastId);
    };
  }, []);

  useEffect(() => {
    if (!isSubscribed && appOpenDates && appOpenDates.length % 5 === 0) {
      stores.General.update((s) => {
        s.isSubscriptionTakeoverShown = true;
      });
    }
  }, [appOpenDates, isSubscribed]);

  return (
    <Page showNavigationBar css={baseStyle}>
      <div css={topStyle}>
        <h1 css={headingStyle}>Your Watchlist</h1>
      </div>
      {(() => {
        if (!watchlistList.length) return <Empty />;

        const availableInvestments = Object.values(investmentsResult.data || investmentsCache || {});

        if (!availableInvestments) return <div />;

        return (
          <div>
            {availableInvestments.length > 0 && (
              <div>
                <InvestmentTable
                  investments={availableInvestments}
                  wiims={wiimsResult.data}
                  columnSelections={columnSelections}
                  onChangeColumnSelections={(index, value) => {
                    stores.TableOptions.update((s) => {
                      if (!s[tableId]?.columnSelections) {
                        s[tableId].columnSelections = columnSelections;
                      }
                      s[tableId].columnSelections![index] = value;
                    });
                  }}
                  sortingRule={sortingRule}
                  onChangeSortingRule={(rule) => {
                    stores.TableOptions.update((s) => {
                      s[tableId].sortingRule = rule;
                    });
                  }}
                  isLoading={investmentsResult.isLoading}
                  filters={watchlistList.length > 5 ? filters : undefined}
                  onSetFilter={
                    watchlistList.length > 5
                      ? (key) => {
                          const updatedFilters = mapValues(filters, () => false);
                          updatedFilters[key] = !filters[key];
                          stores.TableOptions.update((s) => {
                            s[tableId].filters = updatedFilters;
                          });
                        }
                      : undefined
                  }
                />
              </div>
            )}
          </div>
        );
      })()}
      {(() => {
        if (!watchlistList.length) return <div />;

        return (
          <Link
            to="/search"
            css={css`
              display: block;
              margin-top: ${spacing.regular};
            `}
          >
            <Button variant="secondary" block>
              Find more stocks
            </Button>
          </Link>
        );
      })()}
    </Page>
  );
};

export default PortfolioPage;
