import { css } from '@emotion/react';
import { FC, ComponentPropsWithoutRef } from 'react';
import { colors, fontStyles, spacing } from '../styles';

type Props = ComponentPropsWithoutRef<'button'> & {
  variant?: 'link' | 'primary' | 'secondary';
  block?: boolean;
};

const Button: FC<Props> = ({ variant = 'link', block, children, ...props }: Props) => {
  return (
    <button
      type="button"
      css={[
        css`
          border: none;
          padding: 0;
          color: ${colors.petrolBlue};
          font: inherit;
          line-height: inherit;
          border-radius: 4px;
          text-align: center;
          background-color: transparent;

          &:hover {
            cursor: pointer;
          }

          &:disabled {
            cursor: auto;
          }

          html[data-theme='dark'] & {
            color: ${colors.skyBlue};
          }
        `,
        block &&
          css`
            display: block;
            width: 100%;
          `,
        variant === 'link' &&
          css`
            &:disabled {
              color: ${colors.cloud};
            }
          `,
        variant === 'secondary' &&
          css`
            border: ${colors.petrolBlue} 1px solid;
            color: ${colors.petrolBlue};
            padding: ${spacing.centi} ${spacing.regular};
            ${fontStyles.cat};

            html[data-theme='dark'] & {
              color: ${colors.skyBlue};
              border-color: ${colors.skyBlue};
            }

            &:disabled {
              color: ${colors.cloud};
            }
          `,
        variant === 'primary' &&
          css`
            border: transparent 1px solid;
            background-color: ${colors.petrolBlue};
            padding: ${spacing.centi} ${spacing.regular};
            color: ${colors.sugar};
            ${fontStyles.cat};

            html[data-theme='dark'] & {
              background-color: ${colors.skyBlue};
            }

            &:disabled {
              color: ${colors.sugar};
              opacity: 0.5;
            }
          `,
      ]}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
