import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

const updateTheme = async (isDark: boolean) => {
  document.documentElement.setAttribute('data-theme', isDark ? 'dark' : 'light');

  if (Capacitor.isNativePlatform()) {
    try {
      if (Capacitor.getPlatform() === 'ios') {
        await StatusBar.setOverlaysWebView({ overlay: true });
        await StatusBar.setStyle({
          style: isDark ? Style.Light : Style.Dark
        });
      } else if (Capacitor.getPlatform() === 'android') {
        await StatusBar.setBackgroundColor({
          color: isDark ? '#171616' : '#fffefa'
        });
        await StatusBar.setStyle({
          style: isDark ? Style.Light : Style.Dark
        });
      }
    } catch (error) {
      console.error('Error updating StatusBar:', error);
    }
  }
};

const initializeTheme = () => {
  const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

  updateTheme(darkModeMediaQuery.matches);

  darkModeMediaQuery.addEventListener('change', (e) => {
    updateTheme(e.matches);
  });
};

export { initializeTheme, updateTheme }; 