import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { css } from '@emotion/react';
import externalLink from '../../../assets/externalLink.svg';
import { getRiskFactors } from '../../../apis/backend';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';
import { fontStyles, spacing } from '../../../styles';
import RiskDetailsTakeover from './RiskDetailsTakeover';
import RiskFactorPoint from './RiskFactorPoint';

interface Props {
  symbol: string;
}

const RiskFactorsPage: FC<Props> = ({ symbol }) => {
  const query = useQuery(['investments', symbol, 'risk_factors'], async () => getRiskFactors(symbol));
  const [detailsTakeoverId, setDetailsTakeoverId] = useState<string>();

  if (query.isLoading || query.isError || !query.data || query.data.length === 0) return <div />;

  const selectedRiskFactor = query.data.find(({ id }) => id === detailsTakeoverId);

  return (
    <div>
      <ScrollToTopOnMount />
      <p
        css={css`
          margin: ${spacing.regular} 0;
          ${fontStyles.cat}
        `}
      >
        Risk factors are extracted from the company’s{' '}
        <a
          href={query.data[0].sourceDoc}
          css={css`
            white-space: nowrap;
          `}
        >
          10-K filing
          <img
            src={externalLink}
            css={css`
              vertical-align: text-bottom;
            `}
          />
        </a>
        , which are typically more than 50 pages long.
      </p>
      <RiskDetailsTakeover
        isShown={!!selectedRiskFactor}
        onClose={() => setDetailsTakeoverId(undefined)}
        keyPoint={selectedRiskFactor?.keyPoint}
        details={selectedRiskFactor?.details}
      />
      <ul
        css={css`
          margin: 0;
          padding: 0;
          list-style-type: none;
          
        `}
      >
        {query.data.map(({ id, keyPoint }) => (
          <li
            key={id}
            css={css`
              margin-bottom: ${spacing.regular};
            `}
          >
            <RiskFactorPoint keyPoint={keyPoint} onShowDetails={() => setDetailsTakeoverId(id)} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RiskFactorsPage;
