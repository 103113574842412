import { css } from '@emotion/react';
import { FC, useState, useEffect } from 'react';
import { getOverview, GetOverviewResponse, Rating } from '../../../apis/backend';
import Hr from '../../Hr';
import { colors, fontStyles, spacing } from '../../../styles';
import SectionHeading from '../SectionHeading';
import Indicator, { Size } from '../../RatingIndicator';
import { reportError } from '../../../utils/reporting';

interface Props {
  symbol: string;
}

const Overview: FC<Props> = ({ symbol }) => {
  const [data, setData] = useState<GetOverviewResponse | undefined>();
  useEffect(() => {
    (async () => {
      try {
        setData(await getOverview(symbol));
      } catch (e) {
        reportError(e);
      }
    })();
  }, [symbol]);

  if (!data) return <div />;

  const overviewToBottomLineRatingMapping: { [rating: number]: Rating } = {
    5: Rating.Favorable,
    4: Rating.Favorable,
    3: Rating.Average,
    2: Rating.Unfavorable,
    1: Rating.Unfavorable,
  };

  return (
    <div>
      <Hr />
      <SectionHeading text="Bottom Line" />
      <ul
        css={css`
          margin: 0;
          list-style: none;
          position: relative;
          padding-left: ${spacing.hecto};
        `}
      >
        {data.items.map(({ title, text, rating }) => (
          <li key={title}>
            <div
              css={css`
                position: absolute;
                left: 0;
                margin-top: -4px;
              `}
            >
              <Indicator heading={title} rating={overviewToBottomLineRatingMapping[rating]} size={Size.large} />
            </div>
            <div>
              <h3
                css={css`
                  margin: 0;
                  ${fontStyles.lion}
                  html[data-theme='dark'] & {
                    color: ${colors.sugar};
                  }
                `}
              >
                {title}
              </h3>
              <p
                css={css`
                  margin: ${spacing.centi} 0 ${spacing.regular} 0;
                  ${fontStyles.mouse}
                  html[data-theme='dark'] & {
                    color: ${colors.sugar};
                  } 
                `}
              >
                {text}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Overview;
