import { useState, FC, useEffect } from 'react';
import { Purchases } from '@revenuecat/purchases-capacitor'; // Updated import
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { css } from '@emotion/react';
import { Capacitor } from '@capacitor/core';
import { discordInviteUrl } from '../../constants';
import * as stores from '../../stores';
import Page from '../Page';
import { colors, spacing, fontStyles } from '../../styles';
import FeedbackTakeover from '../FeedbackTakeover';
import share from '../../utils/share';
import RowButton from './RowButton';

const MorePage: FC = () => {
  const [isFeedbackTakeoverShown, setIsFeedbackTakeoverShown] = useState(false);
  const restoreMutation = useMutation(() => Purchases.restorePurchases()); // Updated method
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const isSubscribed = purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;

  const styles = {
    darkmodeStyle: css`
      html[data-theme='dark'] & {
        background-color: ${colors.liquorice};
        color: ${colors.sugar};
      }    
  `,
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page showNavigationBar>
      <FeedbackTakeover isShown={isFeedbackTakeoverShown} onClose={() => setIsFeedbackTakeoverShown(false)} />
      <h1
        css={css`
          color: ${colors.liquorice};
          margin: 0 0 ${spacing.deci} 0;
          ${fontStyles.shark};
          ${styles.darkmodeStyle}
        `}
      >
        Community
      </h1>
      <a
        href={discordInviteUrl}
        css={css`
          color: ${colors.liquorice};
          ${styles.darkmodeStyle}
        `}
      >
        <RowButton>Join the discussion on Discord</RowButton>
      </a>
      <RowButton onClick={share}>Share the app</RowButton>

      <h1
        css={css`
          color: ${colors.liquorice};
          margin: ${spacing.regular} 0 ${spacing.deci} 0;
          ${fontStyles.shark};
          ${styles.darkmodeStyle} 
        `}
      >
        Membership
      </h1>
      <p
        css={css`
          margin: ${spacing.deci} 0;
          color: ${colors.tungsten};
          ${fontStyles.cat}
          ${styles.darkmodeStyle} 
        `}
      >
        {isSubscribed
          ? 'Your membership is active — thanks for the support!'
          : 'Unlock earnings call insights, curated news, collections, and more when you subscribe.'}
      </p>
      {isSubscribed && (
        <RowButton
          onClick={() => {
            window.location.href =
              Capacitor.getPlatform() === 'android'
                ? 'https://play.google.com/store/account/subscriptions'
                : 'https://apps.apple.com/account/subscriptions';
          }}
        >
          Manage subscription
        </RowButton>
      )}
      {!isSubscribed && (
        <div>
          <RowButton
            onClick={() => {
              stores.General.update((s) => {
                s.isSubscriptionTakeoverShown = true;
              });
            }}
          >
            Learn more
          </RowButton>
          <RowButton
            isLoading={restoreMutation.isLoading}
            disabled={restoreMutation.isLoading}
            onClick={() => {
              (async () => {
                try {
                  const { customerInfo } = await restoreMutation.mutateAsync();
                  stores.Purchase.update((s) => {
                    s.purchaserInfo = customerInfo;
                  });
                } catch (error) {
                  console.error('Failed to restore purchases:', error);
                  toast('We could not find your subscription.');
                }
              })();
            }}
          >
            Restore purchase
          </RowButton>
        </div>
      )}

      <h1
        css={css`
          color: ${colors.liquorice};
          margin: ${spacing.regular} 0 ${spacing.deci} 0;
          ${fontStyles.shark};
          ${styles.darkmodeStyle} 
        `}
      >
        Feedback
      </h1>
      <p
        css={css`
          margin: ${spacing.deci} 0;
          color: ${colors.tungsten};
          ${fontStyles.cat}
          ${styles.darkmodeStyle} 
        `}
      >
        We’re aiming to build an app that empowers you to make great investments. Tell us how well you enjoyed using
        Bloom and let us know if you have any suggestions!
      </p>

      <RowButton onClick={() => setIsFeedbackTakeoverShown(true)}>Give feedback directly</RowButton>
      <RowButton
        onClick={() =>
          window.open(
            Capacitor.getPlatform() === 'android'
              ? 'https://play.google.com/store/apps/details?id=com.bloom.invest'
              : 'https://apps.apple.com/us/app/bloom-stock-market-research/id1436348671',
            '_blank',
          )
        }
      >
        Rate us on {Capacitor.getPlatform() === 'android' ? 'Google Play' : 'the App Store'}
      </RowButton>

      <h1
        css={css`
          color: ${colors.liquorice};
          margin: ${spacing.regular} 0 ${spacing.deci} 0;
          ${fontStyles.shark};
          ${styles.darkmodeStyle} 
        `}
      >
        Legal
      </h1>
      <RowButton
        onClick={() =>
          window.open(
            'https://cliff-ornament-596.notion.site/Bloom-Disclaimer-44a8d3a0a4dd4e718dbff991eba5d2b9',
            '_blank',
          )
        }
      >
        Disclaimer
      </RowButton>
      <RowButton
        onClick={() =>
          window.open('https://www.notion.so/Bloom-Terms-and-Conditions-b41a2b80c0b14429801adf810856b1d3', '_blank')
        }
      >
        Terms and Conditions
      </RowButton>
      <RowButton
        onClick={() =>
          window.open('https://www.notion.so/Bloom-Privacy-Policy-0c94935f49344ce4957fe459f4cf128d', '_blank')
        }
      >
        Privacy Policy
      </RowButton>
    </Page>
  );
};

export default MorePage;
