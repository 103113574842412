import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import * as stores from '../../stores';
import { useInvestmentsCacheState } from '../../utils/persistedState';
import Button from '../Button';
import { spacing } from '../../styles';
import bookmarkActiveIcon from './bookmarkFull.svg';
import bookmarkInactiveIcon from './bookmarkEmpty.svg';

interface Props {
  symbol: string;
  category: string;
  name: string;
}

const BookmarkButton = ({ symbol, category, name }: Props) => {
  const styles = {
    bookmarkButton: css`
      padding: ${spacing.deci};

      &:focus {
        outline: none;
      }
    `,
    bookmarkIcon: css`
      height: 22px;
      vertical-align: middle;
    `,
  };

  const watchlist = stores.General.useState((s) => s.watchlist);
  const [investmentsCache, setInvestmentsCache] = useInvestmentsCacheState();
  const isBookmarked = !!watchlist[symbol];
  const onClick = (symbol: string): void => {
    if (toast.isActive('bookmark')) {
      toast.update('bookmark', { render: isBookmarked ? 'Removed from watchlist' : 'Added to watchlist' });
    } else {
      toast(isBookmarked ? 'Removed from watchlist' : 'Added to watchlist', { toastId: 'bookmark' });
    }
    stores.General.update((s) => {
      s.watchlist[symbol] = !watchlist[symbol];
    });
    setInvestmentsCache({ ...investmentsCache, [symbol]: { symbol, category, name } });
  };

  return (
    <Button type="button" onClick={() => onClick(symbol)} css={styles.bookmarkButton}>
      <img src={isBookmarked ? bookmarkActiveIcon : bookmarkInactiveIcon} css={styles.bookmarkIcon} alt="Bookmark" />
    </Button>
  );
};

export default BookmarkButton;
