import { FC } from 'react';
import { css } from '@emotion/react';
import { formatDistanceToNowStrict } from 'date-fns';
import { fontStyles, colors } from '../../styles';

interface Props {
  timestamp: string;
  text: string;
}

const styles = {
  base: css`
    margin: 0;
    color: ${colors.silver};
    ${fontStyles.flea};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    
    html[data-theme='dark'] & {
      color: ${colors.sugar};
    }
  `,
  time: css`
    color: ${colors.tungsten};
    
    html[data-theme='dark'] & {
      color: ${colors.skyBlue};
    }
  `,
};

const WiimPreview: FC<Props> = ({ timestamp, text }) => {
  return (
    <p css={styles.base}>
      <span css={styles.time}>{formatDistanceToNowStrict(new Date(timestamp))} ago: </span>
      {text}
    </p>
  );
};

export default WiimPreview;
