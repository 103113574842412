import { css, Interpolation, Theme } from '@emotion/react';
import { FC } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { colors, spacing, fontStyles } from '../../styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  renderTrigger: (props: { styles: Interpolation<Theme> }) => JSX.Element;
  renderBody: () => JSX.Element;
}

const OnboardingPopover: FC<Props> = ({ isOpen, onClose, renderTrigger, renderBody }) => {
  return (
    <span
      css={css`
        .chakra-popover__popper {
          z-index: 102;
        }
      `}
    >
      <Popover id="onboarding" isOpen={isOpen} onClose={onClose} arrowSize={18} gutter={18} closeOnBlur={false}>
        <PopoverTrigger>
          {renderTrigger({
            styles:
              isOpen &&
              css`
                pointer-events: auto;
                z-index: 102;
                position: relative;
                box-shadow: 0 0 0 99999px rgb(0 0 0 / 20%);
              `,
          })}
        </PopoverTrigger>
        <PopoverContent
          onClick={(e) => {
            e.stopPropagation();
          }}
          pointerEvents="auto"
          _focus={{ boxShadow: 'none' }}
          _focusVisible={{ outline: 'none' }}
          css={css`
            padding: ${spacing.deci};
          `}
        >
          <PopoverArrow />
          <PopoverCloseButton size="md" color={colors.silver} />
          <PopoverBody
            css={css`
              text-align: left;
              ${fontStyles.dog}
            `}
          >
            {renderBody()}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </span>
  );
};

export default OnboardingPopover;
